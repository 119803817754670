import axios from "axios";
import { toast } from "react-toastify";

export const GET_COMPANY_TYPE_LIST = 'GET_COMPANY_TYPE_LIST';
export const GET_COMPANY_TYPE_LIST_SUCCESS = 'GET_COMPANY_TYPE_LIST_SUCCESS';
export const GET_COMPANY_TYPE_LIST_FAILURE = 'GET_COMPANY_TYPE_LIST_FAILURE';

export const ADD_COMPANY_TYPE = 'ADD_COMPANY_TYPE';
export const ADD_COMPANY_TYPE_SUCCESS = 'ADD_COMPANY_TYPE_SUCCESS';
export const ADD_COMPANY_TYPE_FAILURE = 'ADD_COMPANY_TYPE_FAILURE';

export const UPDATE_COMPANY_TYPE = 'UPDATE_COMPANY_TYPE';
export const UPDATE_COMPANY_TYPE_SUCCESS = 'UPDATE_COMPANY_TYPE_SUCCESS';
export const UPDATE_COMPANY_TYPE_FAILURE = 'UPDATE_COMPANY_TYPE_FAILURE';

export const DELETE_COMPANY_TYPE = 'DELETE_COMPANY_TYPE';
export const DELETE_COMPANY_TYPE_SUCCESS = 'DELETE_COMPANY_TYPE_SUCCESS';
export const DELETE_COMPANY_TYPE_FAILURE = 'DELETE_COMPANY_TYPE_FAILURE';

export const getCompanyTypeList = (token , searchData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_COMPANY_TYPE_LIST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/company-type/v1?search=${searchData}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_COMPANY_TYPE_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: GET_COMPANY_TYPE_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const addCompanyType = (data, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_COMPANY_TYPE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/company-type/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADD_COMPANY_TYPE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: ADD_COMPANY_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editCompanyType = (data, selectedId, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_COMPANY_TYPE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/company-type/v1?id=${selectedId}`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: UPDATE_COMPANY_TYPE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: UPDATE_COMPANY_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteCompanyType = (ID, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_COMPANY_TYPE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/company-type/v1?id=${ID}`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: DELETE_COMPANY_TYPE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: DELETE_COMPANY_TYPE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const clearCompanyType = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_COMPANY_TYPE" });
    }
};