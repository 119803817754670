import axios from "axios";
import { toast } from "react-toastify";

export const GET_EYE_LIST = 'GET_EYE_LIST';
export const GET_EYE_LIST_SUCCESS = 'GET_EYE_LIST_SUCCESS';
export const GET_EYE_LIST_FAILURE = 'GET_EYE_LIST_FAILURE';

export const ADD_EYE = 'ADD_EYE';
export const ADD_EYE_SUCCESS = 'ADD_EYE_SUCCESS';
export const ADD_EYE_FAILURE = 'ADD_EYE_FAILURE';

export const UPDATE_EYE = 'UPDATE_EYE';
export const UPDATE_EYE_SUCCESS = 'UPDATE_EYE_SUCCESS';
export const UPDATE_EYE_FAILURE = 'UPDATE_EYE_FAILURE';

export const DELETE_EYE = 'DELETE_EYE';
export const DELETE_EYE_SUCCESS = 'DELETE_EYE_SUCCESS';
export const DELETE_EYE_FAILURE = 'DELETE_EYE_FAILURE';

export const clearEye = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_EYE" });
    }
};

export const getEyeList = (token , eyeDetail) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_EYE_LIST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/eyes/v1?search=${eyeDetail}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_EYE_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: GET_EYE_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const addEye = (data, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_EYE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/eyes/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADD_EYE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: ADD_EYE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editEye = (data, selectedId, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_EYE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/eyes/v1?id=${selectedId}`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: UPDATE_EYE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: UPDATE_EYE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteEye = (ID, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_EYE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/eyes/v1?id=${ID}`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: DELETE_EYE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: DELETE_EYE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};
