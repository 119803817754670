import * as notificationAction from '../notificatin/Notification.action';

export const NotificationFeatureKey = "notificationinfo";

const initialState = {
    loading: false,
    errorMsg: "",
    notificationList: [],
    isNotificationChangeStatus: false
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case notificationAction.INSERT_NEW_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case notificationAction.INSERT_NEW_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                isNotificationChangeStatus: true
            }

        case notificationAction.INSERT_NEW_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload.data
            }

        case notificationAction.SEND_EMAIL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case notificationAction.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                isNotificationChangeStatus: true
            }

        case notificationAction.SEND_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload.data
            }
        case notificationAction.NOTIFICATION_HISTORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case notificationAction.NOTIFICATION_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationList: payload.data
            }

        case notificationAction.NOTIFICATION_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload.data
            }

            case notificationAction.INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case notificationAction.INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    isNotificationChangeStatus: true
                }
    
            case notificationAction.INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_FAILURE:
                return {
                    ...state,
                    loading: false,
                    errorMsg: payload.data
                }
    
            case notificationAction.SEND_EMAIL_TO_SINGLE_USER_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case notificationAction.SEND_EMAIL_TO_SINGLE_USER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    isNotificationChangeStatus: true
                }
    
            case notificationAction.SEND_EMAIL_TO_SINGLE_USER_FAILURE:
                return {
                    ...state,
                    loading: false,
                    errorMsg: payload.data
                }
        case "CLEAR_NOTIFICATION_STATUS":
            return {
                ...state,
                isNotificationChangeStatus: false
            }

        default: return state;
    }
}