import axios from "axios";
import { toast } from "react-toastify";

export const GET_CHAT_LIST = 'GET_CHAT_LIST';
export const GET_CHAT_LIST_SUCCESS = 'GET_CHAT_LIST_SUCCESS';
export const GET_CHAT_LIST_FAILURE = 'GET_CHAT_LIST_FAILURE';

export const getChatList = (token ,searchData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_CHAT_LIST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/chat/v1?search=${searchData}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            console.log(res)
            dispatch({ type: GET_CHAT_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CHAT_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const clearChatList = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_CHAT" });
    }
};