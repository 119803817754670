import axios from "axios";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as notificationReducer from "../notificatin/Notification.reducer";

export const INSERT_NEW_NOTIFICATION_REQUEST = 'INSERT_NEW_NOTIFICATION_REQUEST';
export const INSERT_NEW_NOTIFICATION_SUCCESS = 'INSERT_NEW_NOTIFICATION_SUCCESS';
export const INSERT_NEW_NOTIFICATION_FAILURE = 'INSERT_NEW_NOTIFICATION_FAILURE';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_REQUEST = 'INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_REQUEST';
export const INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_SUCCESS = 'INSERT_NEW_NOTIFICATION_To_SINGLE_USER_SUCCESS';
export const INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_FAILURE = 'INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_FAILURE';

export const SEND_EMAIL_TO_SINGLE_USER_REQUEST = 'SEND_EMAIL_TO_SINGLE_USER_REQUEST';
export const SEND_EMAIL_TO_SINGLE_USER_SUCCESS = 'SEND_EMAIL_TO_SINGLE_USER_SUCCESS';
export const SEND_EMAIL_TO_SINGLE_USER_FAILURE = 'SEND_EMAIL_TO_SINGLE_USER_FAILURE';

export const NOTIFICATION_HISTORY_REQUEST = 'NOTIFICATION_HISTORY_REQUEST';
export const NOTIFICATION_HISTORY_SUCCESS = 'NOTIFICATION_HISTORY_SUCCESS';
export const NOTIFICATION_HISTORY_FAILURE = 'NOTIFICATION_HISTORY_FAILURE';

export const sendNotificationToAllDetails = (type, data, img, navigate, token) => {
    return async (dispatch) => {
        try {
            if (img) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/agency/document/pre-signed-url/v1`, {
                    sFileName: img.name,
                    sContentType: img.type
                }, {
                    headers: {
                        Authorization: token
                    }
                });
                const url = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const aws = await axios.put(url, img, { headers: { "Content-Type": img.type } });
                dispatch({ type: INSERT_NEW_NOTIFICATION_REQUEST })
                const urlOfSendNotification = `${process.env.REACT_APP_BASE_URL}/admin/send/notification/${type}/v1`
                const response = await axios.post(urlOfSendNotification, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: INSERT_NEW_NOTIFICATION_SUCCESS, payload: response.data });
                toast("Notification send sucessfully");
                navigate(-1)
            }
            else {
                dispatch({ type: INSERT_NEW_NOTIFICATION_REQUEST })
                const urlOfSendNotification = `${process.env.REACT_APP_BASE_URL}/admin/send/notification/${type}/v1`
                const response = await axios.post(urlOfSendNotification, data, {
                    headers: {
                        Authorization: token
                    }
                });
                dispatch({ type: INSERT_NEW_NOTIFICATION_SUCCESS, payload: response.data });
                toast("Notification send sucessfully");
                navigate(-1)
            }
        } catch (error) {
            dispatch({ type: INSERT_NEW_NOTIFICATION_FAILURE, payload: error.message });
            toast(error.response.statusText);
        }
    }
}

export const sendMailToSingleUser = (id, data, navigate, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_EMAIL_TO_SINGLE_USER_REQUEST })
            const urlOfSendEmail = `${process.env.REACT_APP_BASE_URL}/admin/send/email/single/user/v1?id=${id}`
            const response = await axios.post(urlOfSendEmail, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: SEND_EMAIL_TO_SINGLE_USER_SUCCESS, payload: response.data });
            toast("Email send sucessfully");
            navigate(-1)

        } catch (error) {
            dispatch({ type: SEND_EMAIL_TO_SINGLE_USER_FAILURE, payload: error.message });
            toast(error.response.statusText);
        }
    }
}

export const getNotificationhistoryList = (token) => {
    return async (dispatch) => {
        try {
           
            dispatch({ type: NOTIFICATION_HISTORY_REQUEST })
            const url = `${process.env.REACT_APP_BASE_URL}/admin/notification/history/v1`
            const response = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: NOTIFICATION_HISTORY_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: NOTIFICATION_HISTORY_FAILURE, payload: error.message });
            toast(error.response.statusText);
        }
    }
}
export const sendNotificationToSingleUser = (id, type,data, img, navigate, token) => {
    return async (dispatch) => {
        try {
            if (img) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/agency/document/pre-signed-url/v1`, {
                    sFileName: img.name,
                    sContentType: img.type
                }, {
                    headers: {
                        Authorization: token
                    }
                });
                const url = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const aws = await axios.put(url, img, { headers: { "Content-Type": img.type } });
                dispatch({ type: INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_REQUEST })
                const urlOfSendNotification = `${process.env.REACT_APP_BASE_URL}/admin/send/notification/single/user/v1?id=${id}&type=${type}`
                const response = await axios.post(urlOfSendNotification, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: INSERT_NEW_NOTIFICATION_SUCCESS, payload: response.data });
                toast("Notification send sucessfully");
                navigate(-1)
            }
            else {
                dispatch({ type: INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_REQUEST })
                const urlOfSendNotification = `${process.env.REACT_APP_BASE_URL}/admin/send/notification/single/user/v1?id=${id}&type=${type}`
                const response = await axios.post(urlOfSendNotification, data, {
                    headers: {
                        Authorization: token
                    }
                });
                dispatch({ type: INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_SUCCESS, payload: response.data });
                toast("Notification send sucessfully");
                navigate(-1)
            }
        } catch (error) {
            dispatch({ type: INSERT_NEW_NOTIFICATION_TO_SINGLE_USER_FAILURE, payload: error.message });
            toast(error.response.statusText);
        }
    }
}

export const sendMailToAll = (type, data, navigate, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_EMAIL_REQUEST })
            const urlOfSendEmail = `${process.env.REACT_APP_BASE_URL}/admin/send/email/${type}/v1`
            const response = await axios.post(urlOfSendEmail, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: SEND_EMAIL_SUCCESS, payload: response.data });
            toast("Email send sucessfully");
            navigate(-1)

        } catch (error) {
            dispatch({ type: SEND_EMAIL_FAILURE, payload: error.message });
            toast(error.response.statusText);
        }
    }
}
