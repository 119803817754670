import * as hairActions from './hair.actions';

export const hairFeatureKey = "hairInfo";

const initialState = {
    loading: false,
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
    errorMsg: "",
    hairList: []
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get bank details
        case hairActions.GET_HAIR_LIST:
            return {
                ...state,
                loading: true
            }
        case hairActions.GET_HAIR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                hairList: payload.data
            }
        case hairActions.GET_HAIR_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case hairActions.ADD_HAIR:
            return {
                ...state,
                loading: true,
                isAdded: false
            }

        case hairActions.ADD_HAIR_SUCCESS:
            return {
                ...state,
                loading: false,
                isAdded: true
            }

        case hairActions.ADD_HAIR_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case hairActions.UPDATE_HAIR:
            return {
                ...state,
                loading: true,
                isUpdated: false
            }

        case hairActions.UPDATE_HAIR_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case hairActions.UPDATE_HAIR_FAILURE:
            return {
                ...state,
                loading: false,
                isUpdated: false,
                errorMsg: payload
            }

        case hairActions.DELETE_HAIR:
            return {
                ...state,
                loading: true,
                isDeleted: false
            }

        case hairActions.DELETE_HAIR_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case hairActions.DELETE_HAIR_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_HAIR":
            return {
                ...state,
                loading: false,
                isUpdated: false,
                isAdded: false,
                isDeleted: false,
            }
        default: return state;
    }
};