import * as practitionerActions from "./practitioner.actions";

export const practitionerFeatureKey = "practitionerInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    loginUser: {},
    patientList: [],
    patientDetails: {},
    isPatientChangeStatus: false,
    isPractitionerChangeStatus: false
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        // set new password
        case practitionerActions.SET_NEW_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case practitionerActions.SET_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case practitionerActions.SET_NEW_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};