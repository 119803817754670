import * as categoryActions from './companyType.actions';

export const categoryFeatureKey = "companyTypeInfo";

const initialState = {
    loading: false,
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
    errorMsg: "",
    companyTypeList: [],
    isUpdateBankDetails: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get bank details
        case categoryActions.GET_COMPANY_TYPE_LIST:
            return {
                ...state,
                loading: true
            }
        case categoryActions.GET_COMPANY_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                companyTypeList: payload.data
            }
        case categoryActions.GET_COMPANY_TYPE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case categoryActions.ADD_COMPANY_TYPE:
            return {
                ...state,
                loading: true,
                isAdded: false
            }

        case categoryActions.ADD_COMPANY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAdded: true
            }

        case categoryActions.ADD_COMPANY_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case categoryActions.UPDATE_COMPANY_TYPE:
            return {
                ...state,
                loading: true,
                isUpdated: false
            }

        case categoryActions.UPDATE_COMPANY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case categoryActions.UPDATE_COMPANY_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                isUpdated: false,
                errorMsg: payload
            }

        case categoryActions.DELETE_COMPANY_TYPE:
            return {
                ...state,
                loading: true,
                isDeleted: false
            }

        case categoryActions.DELETE_COMPANY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case categoryActions.DELETE_COMPANY_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_COMPANY_TYPE":
            return {
                ...state,
                loading: false,
                isUpdated: false,
                isAdded: false,
                isDeleted: false,
                isUpdateBankDetails: false
            }
        default: return state;
    }
};