import * as originActions from './origin.actions';

export const originFeatureKey = "originInfo";

const initialState = {
    loading: false,
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
    errorMsg: "",
    originList: []
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case "CLEAR_ORIGIN":
            return {
                ...state,
                loading: false,
                isUpdated: false,
                isAdded: false,
                isDeleted: false,
            }
        // get bank details
        case originActions.GET_ORIGIN:
            return {
                ...state,
                loading: true
            }
        case originActions.GET_ORIGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                originList: payload.data
            }
        case originActions.GET_ORIGIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case originActions.ADD_ORIGIN:
            return {
                ...state,
                loading: true,
                isAdded: false
            }

        case originActions.ADD_ORIGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAdded: true
            }

        case originActions.ADD_ORIGIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case originActions.UPDATE_ORIGIN:
            return {
                ...state,
                loading: true,
                isUpdated: false
            }

        case originActions.UPDATE_ORIGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case originActions.UPDATE_ORIGIN_FAILURE:
            return {
                ...state,
                loading: false,
                isUpdated: false,
                errorMsg: payload
            }

        case originActions.DELETE_ORIGIN:
            return {
                ...state,
                loading: true,
                isDeleted: false
            }

        case originActions.DELETE_ORIGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case originActions.DELETE_ORIGIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};