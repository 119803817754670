import * as adminActions from './admin.actions';

export const adminFeatureKey = "adminInfo";

const initialState = {
    loading: false,
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
    errorMsg: "",
    adminList: []
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get bank details
        case adminActions.GET_ADMIN_LIST:
            return {
                ...state,
                loading: true
            }
        case adminActions.GET_ADMIN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                adminList: payload.data
            }
        case adminActions.GET_ADMIN_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // add 
        case adminActions.ADD_ADMIN:
            return {
                ...state,
                loading: true,
                isAdded: false
            }

        case adminActions.ADD_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAdded: true
            }

        case adminActions.ADD_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }
        
        // update
        case adminActions.UPDATE_ADMIN:
            return {
                ...state,
                loading: true,
                isUpdated: false
            }

        case adminActions.UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case adminActions.UPDATE_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                isUpdated: false,
                errorMsg: payload
            }
        
        // delete
        case adminActions.DELETE_ADMIN:
            return {
                ...state,
                loading: true,
                isDeleted: false
            }

        case adminActions.DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case adminActions.DELETE_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // clear
        case "CLEAR_ADMIN":
            return {
                ...state,
                loading: false,
                isUpdated: false,
                isAdded: false,
                isDeleted: false
            }
        default: return state;
    }
};