import * as eyeActions from './eye.actions';

export const eyeFeatureKey = "eyeInfo";

const initialState = {
    loading: false,
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
    errorMsg: "",
    eyeList: []
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get bank details
        case eyeActions.GET_EYE_LIST:
            return {
                ...state,
                loading: true
            }
        case eyeActions.GET_EYE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                eyeList: payload.data
            }
        case eyeActions.GET_EYE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case eyeActions.ADD_EYE:
            return {
                ...state,
                loading: true,
                isAdded: false
            }

        case eyeActions.ADD_EYE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAdded: true
            }

        case eyeActions.ADD_EYE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case eyeActions.UPDATE_EYE:
            return {
                ...state,
                loading: true,
                isUpdated: false
            }

        case eyeActions.UPDATE_EYE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case eyeActions.UPDATE_EYE_FAILURE:
            return {
                ...state,
                loading: false,
                isUpdated: false,
                errorMsg: payload
            }

        case eyeActions.DELETE_EYE:
            return {
                ...state,
                loading: true,
                isDeleted: false
            }

        case eyeActions.DELETE_EYE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case eyeActions.DELETE_EYE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_EYE":
            return {
                ...state,
                loading: false,
                isUpdated: false,
                isAdded: false,
                isDeleted: false,
            }
        default: return state;
    }
};