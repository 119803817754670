import * as categoryActions from './category.actions';

export const categoryFeatureKey = "categoryInfo";

const initialState = {
    loading: false,
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
    errorMsg: "",
    categoryList: [],
    categoryDetail: {},
    isUpdateBankDetails: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get bank details
        case categoryActions.GET_CATEGORY_LIST:
            return {
                ...state,
                loading: true
            }
        case categoryActions.GET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                categoryList: payload.data
            }
        case categoryActions.GET_CATEGORY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case categoryActions.ADD_CATEGORY:
            return {
                ...state,
                loading: true,
                isAdded: false
            }

        case categoryActions.ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                isAdded: true
            }

        case categoryActions.ADD_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case categoryActions.UPDATE_CATEGORY:
            return {
                ...state,
                loading: true,
                isUpdated: false
            }

        case categoryActions.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case categoryActions.UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                isUpdated: false,
                errorMsg: payload
            }

        case categoryActions.DELETE_CATEGORY:
            return {
                ...state,
                loading: true,
                isDeleted: false
            }

        case categoryActions.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case categoryActions.DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }
        case "CLEAR_CATEGORY":
            return {
                ...state,
                loading: false,
                isUpdated: false,
                isAdded: false,
                isDeleted: false,
                isUpdateBankDetails: false
            }
        default: return state;
    }
};