import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as practitionerActions from "../redux/practitioner/practitioner.actions";

const NewPassword = () => {

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const resetToken = useParams().resettoken;
  const dispacth = useDispatch();
  const navigate = useNavigate();

  const handleSetNewPassword = (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      toast("Please Enter your New Password.");
      return;
    }
    if (password !== confirmPassword) {
      toast("Password and Confirm Password Does not Match.");
      return;
    }
    dispacth(practitionerActions.setNewPassword(resetToken, password, navigate));
  };

  return (
    <>
      <React.Fragment>
        <Container style={{ marginTop: "150px" }}>
          <Row>
            <Col md='5' className="m-auto">
              <Form onSubmit={e => handleSetNewPassword(e)}>
                <h3>Create New Password</h3>
                <div className="mb-3">
                  <Label>New Password</Label>
                  <Input
                    type="password"
                    placeholder="Set New Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <Label>Confirm Password</Label>
                  <Input
                    type="password"
                    placeholder="Reenter Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="d-grid">
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </>
  );
};

export default NewPassword;
