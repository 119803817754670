import * as mediaActions from './media.actions';

export const mediaFeatureKey = "mediaInfo";

const initialState = {
    loading: false,
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
    errorMsg: "",
    mediaList: {}
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case "CLEAR_MEDIA":
            return {
                ...state,
                loading: false,
                isUpdated: false,
                isAdded: false,
                isDeleted: false
            }
        // get bank details
        case mediaActions.GET_MEDIA_LIST:
            return {
                ...state,
                loading: true
            }
        case mediaActions.GET_MEDIA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                mediaList: payload.data
            }
        case mediaActions.GET_MEDIA_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }


        case mediaActions.ADD_MEDIA:
            return {
                ...state,
                loading: true,
                isAdded: false
            }

        case mediaActions.ADD_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                isAdded: true
            }

        case mediaActions.ADD_MEDIA_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case mediaActions.UPDATE_MEDIA:
            return {
                ...state,
                loading: true,
                isUpdated: false
            }

        case mediaActions.UPDATE_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case mediaActions.UPDATE_MEDIA_FAILURE:
            return {
                ...state,
                loading: false,
                isUpdated: false,
                errorMsg: payload
            }

        case mediaActions.DELETE_MEDIA:
            return {
                ...state,
                loading: true,
                isDeleted: false
            }

        case mediaActions.DELETE_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case mediaActions.DELETE_MEDIA_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};